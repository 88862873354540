import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import BtnLg from 'components/buttons/btnlg';
import HeroSecondaryPage from 'components/hero/HeroSecondaryPage';
import { RichText } from 'prismic-reactjs';
import { withStyles } from '@material-ui/styles';
import linkResolver from 'utils/linkResolver';
import { withPreview } from 'gatsby-source-prismic';

const styles = theme => ({
  overlay: {
    width: '100%',
  },
  title: {
    paddingTop: '5px',
    display: 'inline-block',
  },
  hoverBg: {
    top: '80px',
    left: '0px',
    width: '100%',
    height: 'calc(100% - 80px)',
    position: 'absolute',
    zIndex: '-1',
    boxShadow: '0px 0px 4px 0px rgba(19, 29, 49, 0)',
    transition: 'box-shadow 0.5s ease-in-out',
  },
  link: {
    '&:hover': {
      '& .title': {},
      '& .hoverBg': {
        boxShadow: '0px 0px 4px 0px rgba(19, 29, 49, 0.23)',
      },
    },
  },
  logoWrapper: {
    height: '35px',
  },
  logoWrapperLarge: {
    height: '80px',
  },
  grid_title: {
    color: theme.palette.text.green,
  },
  department_card: {
    height: '100%',
  },
  greyBg: {
    backgroundColor: '#F9F6F3',
  },
});

function DepartmentCard(props) {
  const { classes, item } = props;
  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Link className={classes.link} to={item.department.url}>
        <Box position="relative" className={classes.department_card}>
          <Box mb={2}>
            <Box component="span" className={classes.overlay}>
              {item.department.document.data.image &&
                item.department.document.data.image.url && (
                  <img
                    className="w-100"
                    alt={
                      item.department.document.data.image.alt
                        ? item.department.document.data.image.alt
                        : item.department.document.data.title.text
                    }
                    src={
                      item.department.document.data.image
                        ? item.department.document.data.image.url +
                          '&crop=center&w=500&min-h=500'
                        : ''
                    }
                  />
                )}
            </Box>
          </Box>
          <Box pb={1} display="flex" flexDirection="column" alignItems="center">
            <Typography
              component="h4"
              variant="h4"
              align="center"
              className={`${classes.title} title`}
              style={{ fontWeight: '900' }}
              gutterBottom
            >
              {item.department.document.data.title.text}
            </Typography>
          </Box>
        </Box>
      </Link>
    </Grid>
  );
}

DepartmentCard.propTypes = {
  classes: PropTypes.object,
  item: PropTypes.object.isRequired,
};

function Departments(props) {
  const { data, classes } = props;
  const doc = data.allPrismicDepartments.nodes.slice(0, 1).pop();

  if (!doc) return null;

  return (
    <Layout headerOpacity="0.9">
      <Box component="div">
        <HeroSecondaryPage
          heroTitle={doc.data.banner_title.text}
          heroImage={doc.data.banner_image.url}
          heroContent={doc.data.banner_content.raw}
        />
        <Box className={classes.greyBg} py={4}>
          <Container>
            <Box mb={5} mt={{ xs: 5, sm: 5, md: 0 }}>
              <Typography
                component="div"
                variant="h2"
                align="center"
                className={classes.grid_title}
              >
                {doc.data.department_grid_title.text}
              </Typography>
            </Box>

            <Box className="spaced-grid-wrapper">
              <Box
                component={Grid}
                container
                spacing={2}
                justifyContent="center"
              >
                {doc.data.departments_grid.map((item, i) => {
                  return (
                    <React.Fragment key={i}>
                      <DepartmentCard item={item} classes={classes} />
                    </React.Fragment>
                  );
                })}
              </Box>
            </Box>
            <Box display="flex" justifyContent="center" pt={8}>
              <BtnLg
                color="#044D29"
                hoverColor="#044D29"
                link="opportunities"
                content={doc.data.link_title.text}
              />
            </Box>
          </Container>
        </Box>
      </Box>
    </Layout>
  );
}

Departments.propTypes = {
  data: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withPreview(Departments));

export const query = graphql`
  query DepartmentsPageQuery {
    prismicGlobalLayout {
      data {
        spotlight_a_department
        select_which_department_to_spotlight {
          type
          uid
          slug
          id
        }
      }
    }
    allPrismicDepartments {
      nodes {
        data {
          banner_content {
            text
            raw
          }
          banner_image {
            url
            alt
          }
          banner_title {
            text
          }
          title {
            text
          }
          link_title {
            text
          }
          departments_grid {
            department {
              url
              uid
              document {
                ... on PrismicDepartment {
                  id
                  data {
                    image {
                      url(imgixParams: { fit: "crop" })
                      alt
                    }
                    title {
                      text
                    }
                    caption {
                      text
                      raw
                    }
                  }
                }
              }
            }
          }
          department_grid_title {
            text
          }
        }
      }
    }
  }
`;
